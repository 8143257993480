// ScheduleMeetingForm.js
import { Google } from '@mui/icons-material';
import React, { useState } from 'react';

const ScheduleMeetingForm = () => {
  const [meetingDetails, setMeetingDetails] = useState({
    title: '',
    startTime: '',
    email: '',
    phone:'',
    description: '',
  });

  const handleChange = (e) => {
    setMeetingDetails({
      ...meetingDetails,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    alert("result.message");

    // Call the Netlify function to schedule the meeting
    const response = await fetch('/.netlify/functions/scheduleMeeting', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(meetingDetails),
    });
    const result = await response.json();
    alert(result.message);
  };

  return (
    <div style={{ marginTop: 100 }}>
    <h6>Randevu oluşturun</h6>
    <input
      className="form-control"
      type="text"
      name="name"
      value={meetingDetails.name}
      onChange={handleChange}
      placeholder="Adınız Soyadınız"
    />
    <input
      className="form-control"
      type="text"
      name="title"
      value={meetingDetails.title}
      onChange={handleChange}
      placeholder="Hangi hizmet/ürün hakkında bilgi almak istiyorsunuz?"
    />
    <input
      className="form-control"
      type="text"
      name="description"
      value={meetingDetails.description}
      onChange={handleChange}
      placeholder="Ayrıntıları buraya yazabilirsiniz."
    />
        <input
      className="form-control"
      type="text"
      name="email"
      value={meetingDetails.email}
      onChange={handleChange}
      placeholder="Email Adresiniz"
    />
            <input
      className="form-control"
      type="text"
      name="phone"
      value={meetingDetails.phone}
      onChange={handleChange}
      placeholder="Telefon Numaranız"
    />
    <p>Randevu tarih/saati seçiniz</p>
    <input
      type="datetime-local"
      className="form-control"
      name="startTime"
      value={meetingDetails.startTime}
      onChange={handleChange}
    />
    <button className="btn ac_btn" onClick={handleSubmit}>
      <Google /> Randevu oluştur
    </button>
  </div>
  );
};

export default ScheduleMeetingForm;
