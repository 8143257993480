import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { naturalBlack, naturalWhite } from '../global/colors';
import SocialAccounts from './social-accounts';

function Copyright() {
    return (
        <Typography variant="body2" align="center">
            {'Copyright © '}
            <Link color="inherit" href='https://samilalgul.com/'>
                ŞAMİL ALGÜL
            </Link>
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

function Footer(props) {
    const { description, title } = props;

    return (
        <Box component="footer" sx={{ py: 6, backgroundColor: naturalBlack }}>
            <Container maxWidth="lg" style={{
                color: naturalWhite
            }}>
                <SocialAccounts/>
                <Typography variant="h6" align="center" gutterBottom>
                    <img src={title}/>
                </Typography>
                <Typography
                    variant="subtitle1"
                    align="center"
                    component="p"
                >
                    {description}
                </Typography>
                <Copyright />
            </Container>
        </Box>
    );
}

Footer.propTypes = {
    description: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
};

export default Footer;