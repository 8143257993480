import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import { Grid, IconButton } from "@mui/material";
import {
  X,
  YouTube,
  Instagram,
  Facebook,
  Mail,
  WhatsApp,
  LinkedIn,
  Telegram,
  Pinterest,
} from "@mui/icons-material";
import { styled } from "@mui/material/styles";

const GradientIconButton = styled(IconButton)(({ theme }) => ({
  background:
    "linear-gradient(90deg, rgba(172, 109, 248, 1) 0%, rgba(27, 255, 255, 1) 100%)",
  color: "white", // İkon rengini beyaz yapabilirsiniz
  width: "fit-content",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "50%", // Dairesel buton için
  padding: theme.spacing(1), // İsteğe bağlı padding
  "&:hover": {
    background:
      "linear-gradient(90deg, rgba(172, 109, 248, 0.8) 0%, rgba(27, 255, 255, 0.8) 100%)",
  },
}));
export default function SocialAccounts({ link, title, body }) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleMenuItemClick = (event, index) => {
    setOpen(false);
  };

  const shareList = [
    {
      Name: "X",
      Component: (
        <IconButton
          sx={{
            width: "fit",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color:
              "linear-gradient(90deg,rgba(172, 109, 248, 1) 0%,rgba(27, 255, 255, 1) 100%)",
          }}
          href={`https://x.com/softcaveware`}
          data-action="share/whatsapp/share"
          aria-label="share"
        >
          <X fontSize="large" />
        </IconButton>
      ),
      Visible: true,
    },
    {
      Name: "LinkenIn",
      Component: (
        <IconButton
          sx={{ width: "fit", color: "black" }}
          href={`https://tr.linkedin.com/company/softcaveware
              `}
          data-action="share/LinkenIn/share"
          aria-label="share"
        >
          <LinkedIn fontSize="large" />
        </IconButton>
      ),
      Visible: true,
    },
    {
      Name: "Instagram",
      Component: (
        <IconButton
          sx={{ width: "fit", color: "black" }}
          href={`https://www.instagram.com/softcaveware/`}
        >
          <Instagram fontSize="large" />
        </IconButton>
      ),
      Visible: true,
    },
    {
      Name: "Youtube",
      Component: (
        <IconButton
          sx={{ width: "fit", color: "black" }}
          href={`https://www.youtube.com/channel/UCdJIChPbrLti2bFc3AHH6xQ`}
        >
          <YouTube fontSize="large" />
        </IconButton>
      ),
      Visible: true,
    },
    {
      Name: "Whatsapp",
      Component: (
        <IconButton
          style={{ width: "100%" }}
          color="success"
          href={`https://api.whatsapp.com/send?text=${link}/`}
          data-action="share/whatsapp/share"
          aria-label="share"
        >
          <WhatsApp
            style={{
              color: "white",
              backgroundColor: "#25D366",
              borderRadius: 100,
            }}
            fontSize="large"
          />
        </IconButton>
      ),
    },
    {
      Name: "Telegram",
      Component: (
        <IconButton
          sx={{ width: "100%", color: "#0088cc" }}
          href={`https://t.me/share/url?url=${link}`} // with messages https://t.me/share/url?url=${link}&text=Your%20message%20here
          data-action="share/telegram/share"
          aria-label="share"
        >
          <Telegram fontSize="large" />
        </IconButton>
      ),
    },
    {
      Name: "Facebook",
      Component: (
        <IconButton
          sx={{ width: "100%", color: "#316FF6" }}
          target="_blank"
          href={`https://www.facebook.com/sharer/sharer.php?u=${link}`}
          aria-label="share"
        >
          <Facebook fontSize="large" />
        </IconButton>
      ),
    },
    {
      Name: "Pinterest",
      Component: (
        <IconButton
          sx={{ width: "100%", color: "#E60023" }}
          href={`http://pinterest.com/pin/create/link/?url=
          ${link}
            `}
          data-action="share/Pinterest/share"
          aria-label="share"
        >
          <Pinterest fontSize="large" />
        </IconButton>
      ),
    },
    {
      Name: "Mail",
      Component: (
        <IconButton sx={{ width: "fit", color: "black" }} href={``}>
          <Mail fontSize="large" />
        </IconButton>
      ),
    },
  ];

  return (
    <React.Fragment>
      <Grid
        container
        sx={{ display: "flex", flexDirection: "row" }}
        spacing={0}
      >
        {shareList
          .filter((x) => x.Visible)
          .map((share, index) => (
            <Grid
              item
              xs={12 / shareList.filter((x) => x.Visible).length}
              width="100%"
            >
              <div
                style={{
                  width: "fit",
                  padding: 0,
                  margin: 0,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                key={share.Name}
              >
                <GradientIconButton> {share.Component}</GradientIconButton>
              </div>
            </Grid>
          ))}
      </Grid>
    </React.Fragment>
  );
}
