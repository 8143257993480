import logo from './logo.svg';
import './App.css';
import PropTypes from 'prop-types';
import DrawerAppBar from './Components/DrawerAppBar';
import { Routes, Route, Router, BrowserRouter, MemoryRouter, useLocation } from 'react-router-dom'
import Contact from './pages/Contact/Contact';
import Home from './pages/Home/Home';
import { StaticRouter } from 'react-router-dom/server';
import { Box, Typography } from '@mui/material';
import { naturalBlack } from './global/colors';
import co_logo from "./Resources/co_logo.png"
import Footer from './Components/Footer';
import About from './pages/About/about';
import softcaveware from './Resources/softcaveware.png'


function CurrentRoute(props) {
  const location = useLocation();
return (
    <Typography variant="body2" sx={{ pb: 2 }} color="text.secondary">
      Current route: {location.pathname}
    </Typography>
  );
}

function App() {
  return (

    <div style={{backgroundColor:naturalBlack}}>
      <BrowserRouter>
      <DrawerAppBar />
      <Box sx={{marginTop:'64px',backgroundColor:naturalBlack }}>
      <Routes>
          <Route index element={<Home />} />
          <Route path='*' element={<CurrentRoute/>}/>
          <Route path='contact' element={<Contact/>}/>
          <Route path='about' element={<About/>}/>
        </Routes>
      </Box>
      </BrowserRouter>
      <Footer
        title={softcaveware}
        description="Software Solutions"
      />
    </div>


  );
}

export default App;
