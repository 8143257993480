import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { naturalBlack, naturalWhite, companyRed } from '../../global/colors';
import dynamicWeb from '../../Resources/dynamicWeb.png';
import staticWeb from '../../Resources/staticWeb.png';
import travelMobile from '../../Resources/travelMobile.png';
import travelDesktop from '../../Resources/travelDesktop.png';
import backEnd1 from "../../Resources/backend1.png";
import backEnd2 from "../../Resources/backend2.png";
import stat1 from "../../Resources/stat1.png";
import stat2 from "../../Resources/stat2.png"
import seo1 from "../../Resources/seo1.png";
import seo2 from "../../Resources/seo2.png";
import onlineSatis from "../../Resources/onlineSatis1.png";
import onlineSatis2 from "../../Resources/onlineSatis2.png";
import './style.css'
import { useNavigate } from 'react-router-dom';

const dialogContents =
  [
    {
      id: 1,
      Title: "Web Tasarım",
      content1: "Müşterilerimize benzersiz ve etkileyici web tasarım çözümleri sunmak için buradayız."
        + "Profesyonel ekibimizle birlikte, işletmenizin çevrimiçi varlığını güçlendirmek, markanızı"
        + "öne çıkarmak ve hedef kitlenizin dikkatini çekmek için modern ve kullanıcı dostu web siteleri tasarlıyoruz."
        + "İnternet çağında, bir işletmenin başarısı çoğunlukla çevrimiçi görünümüne bağlıdır."
        + "İşte bu noktada sizin için en iyi web tasarım çözümlerini sunuyoruz. Amacımız, işletmenizi en iyi şekilde temsil eden,"
        + "etkileyici bir web sitesi oluşturarak marka değerinizi artırmaktır. Size özel olarak tasarlanmış bir web sitesi ile işletmeniz,"
        + " rakipleriniz arasından sıyrılacak ve müşterilerinize unutulmaz bir deneyim sunacaktır.",
      images: [travelDesktop, travelMobile, ""]
    },
    {
      id: 2,
      Title: "Static&Dinamik Web",
      content1: "İşletmenizin çevrimiçi varlığını güçlendirmek, kullanıcıları etkilemek ve rekabet avantajı elde etmek için doğru web sitesini seçmek büyük bir önem taşımaktadır.",
      content2: "Statik Web Siteleri:\n" +
        "Statik web siteleri, temel olarak HTML ve CSS gibi sabit dosyalardan oluşan basit ve düşük karmaşıklığa sahip web siteleridir. Bu tür siteler genellikle bilgi sunmak, ürünlerinizi tanıtmak veya iletişim bilgilerinizi paylaşmak gibi basit amaçlarla kullanılır.\n" +
        "Avantajları:\n" +
        "- Basit yapısı sayesinde hızlı bir şekilde yüklenir.\n" +
        "- Güvenlik açıkları azdır, çünkü kullanıcıların etkileşime girebileceği dinamik bileşenler bulunmaz.\n" +
        "- Düşük maliyetli bir çözümdür ve bakım gerektirmez.",
      content3: "Dinamik Web Siteleri:\n" +
        "Dinamik web siteleri, genellikle veritabanlarına dayanan, kullanıcıların etkileşimde bulunabileceği ve içeriklerin gerçek zamanlı olarak güncellendiği daha karmaşık web siteleridir. Bu tür siteler genellikle e-ticaret, sosyal medya veya bloglar gibi interaktif içeriklerin olduğu projelerde tercih edilir.\n" +
        "Avantajları:\n" +
        "- Kullanıcılarla etkileşime geçme imkanı sunar, formlar, yorumlar, üyelik sistemi gibi dinamik özelliklere sahiptir.\n" +
        "- İçerik yönetim sistemleri (CMS) ile kolayca güncellenebilir ve içerik eklemek veya düzenlemek için teknik bilgi gerektirmez.\n" +
        "- Ölçeklenebilirlik ve genişleme için daha fazla esneklik sağlar.",
      content4: "Hangi web sitesi türü sizin için daha uygun?\n" +
        "Bu karar, işletmenizin hedeflerine, ihtiyaçlarına ve bütçesine bağlıdır. Eğer sadece temel bir web sitesi ihtiyacınız varsa ve düzenli güncellemeler yapmayı planlamıyorsanız, statik bir web sitesi sizi tatmin edebilir. Ancak kullanıcılarla etkileşimde bulunmak, içerikleri sıkça güncellemek veya işletmenizin büyüme potansiyeline sahip olması gibi faktörler, dinamik bir web sitesini tercih etmenizi gerektirebilir.\n" +
        "Uzman ekibimiz, sizin için en iyi çözümü belirlemek için her zaman burada. İhtiyaçlarınıza uygun bir web sitesi oluşturmak ve işletmenizin dijital varlığını güçlendirmek için bize başvurabilirsiniz.",
      images: [dynamicWeb, staticWeb, ""]
    },
    {
      id: 3,
      Title: "Back-end/Server Geliştirme",
      content1: "İşletmenizin çevrimiçi varlığını güçlendirmek ve kullanıcılarınıza en iyi deneyimi sunmak için web sitenizin arkasında güçlü bir altyapıya ihtiyaç duyarsınız. Bu noktada, backend server side geliştirme devreye girer. İşte size backend geliştirmenin önemini ve avantajlarını anlatan bir müşteri tanıtımı:",
      content2: "Backend Server Side Geliştirme Nedir?\nBackend server side geliştirme, web sitenizin arka planında çalışan, veritabanı yönetimi, sunucu işlemleri ve kullanıcıların verileriyle etkileşim sağlayan kodların oluşturulması sürecidir. Bu, web sitenizin işlevselliğini ve performansını yöneten temel bileşenleri içerir.",
      content3: "Neden Backend Server Side Geliştirme Önemlidir?\n1. Veritabanı Yönetimi: Backend geliştirme, veritabanı yönetimi için gerekli olan veri tabanı bağlantıları, sorgular ve veri manipülasyonunu sağlar. Bu, kullanıcı verilerinin güvenli bir şekilde saklanmasını, erişilmesini ve yönetilmesini sağlar.\n2. Güvenlik: Backend geliştirme, web sitenizin güvenliğini sağlamak için önemli bir rol oynar. Geliştiriciler, kullanıcı kimlik doğrulaması, veri doğrulama, şifreleme ve güvenlik önlemlerini uygulayarak saldırılara karşı önlemler alır.\n3. Performans ve Ölçeklenebilirlik: Backend geliştirme, web sitenizin performansını optimize etmek ve ölçeklenebilirliği artırmak için gereken yapıyı sağlar. Veri tabanı optimizasyonu, önbellekleme, sunucu yük dengeleme ve veri sıkıştırma gibi tekniklerle kullanıcı deneyimini iyileştirir.\n4. API ve Entegrasyon: Backend geliştirme, üçüncü taraf API'ları ve diğer servislerle entegrasyonu mümkün kılar. Bu, ödeme işlemleri, sosyal medya paylaşımları, haritalar ve daha fazlası gibi farklı hizmetlere erişimi kolaylaştırır.",
      content4: "Backend Geliştirmenin Avantajları:\n1. Özelleştirilebilirlik: Backend geliştirme, ihtiyaçlarınıza ve işletmenizin gereksinimlerine göre özelleştirilebilir bir altyapı sağlar. İş süreçlerinize ve veri yönetimine uygun bir şekilde kodlanmış backend, size tam kontrol ve esneklik sunar.\n2. Veritabanı Yönetimi: Backend geliştirme, veritabanı yönetimi için gerekli olan işlemleri kolaylaştırır. Veri tabanı bağlantısı, sorgular ve güncellemeler gibi işlemler backend tarafında gerçekleştirilir.\n3. Ölçeklenebilirlik: Backend geliştirme, web sitenizin büyümesine ve kullanıcı trafiğinin artmasına uyum sağlamak için ölçeklenebilir bir altyapı sunar. Bu, web sitenizin performansının ve kullanıcı deneyiminin sabit kalmasını sağlar.\n4. Güvenlik: Backend geliştirme, kullanıcı verilerinin güvenliğini sağlamak için önemli bir rol oynar. Veri şifreleme, kimlik doğrulama ve güvenlik önlemleri, web sitenizin güvenliğini artırır.\n\nBackend server side geliştirme, web sitenizin güçlü ve sağlam bir altyapıya sahip olmasını sağlar. İşletmenizin ihtiyaçlarına özel çözümler sunarak veritabanı yönetimi, güvenlik, performans ve entegrasyon gibi önemli faktörleri optimize eder. Uzman ekibimiz, backend geliştirme konusunda deneyimli ve size en iyi çözümü sunmak için burada. İşletmenizin büyümesine katkıda bulunmak ve rekabet avantajı elde etmek için bize başvurabilirsiniz.",
      images: [backEnd1, backEnd2, ""]
    },
    {
      id: 4,
      Title: "SEO",
      content1: "İşletmenizin çevrimiçi görünürlüğünü artırmak, potansiyel müşterilere ulaşmak ve rekabet ortamında öne çıkmak için SEO (Search Engine Optimization - Arama Motoru Optimizasyonu) stratejilerini kullanmanız önemlidir. İşte size SEO'nun önemini ve avantajlarını anlatan bir müşteri tanıtımı:",
      content2: "SEO Nedir?\nSEO, web sitenizin arama motorları tarafından daha iyi anlaşılmasını ve sıralamalarında yükselmesini sağlayan bir dizi teknik ve stratejiler bütünüdür. Bu, organik arama sonuçlarında web sitenizin daha üst sıralarda görünmesini ve daha fazla trafik çekmesini hedefler.",
      content3: "Neden SEO Önemlidir?\n1. Artan Görünürlük: SEO, web sitenizin arama motorlarında daha iyi sıralanmasını sağlayarak görünürlüğünüzü artırır. İlgili anahtar kelimelerde üst sıralarda yer almak, daha fazla tıklama ve potansiyel müşteriye ulaşma şansınızı artırır.\n" +
        "2. Hedeflenen Trafik: SEO stratejileri, hedef kitlenizin arama yaparken kullandığı anahtar kelimeleri hedef alır. Bu sayede web sitenize gelen ziyaretçilerin ilgili ve potansiyel müşteri olma olasılığı daha yüksektir.\n3. İçerik Kalitesi ve Kullanıcı Deneyimi: SEO, kaliteli ve kullanıcı odaklı içerik üretmenizi teşvik eder. Arama motorları, kullanıcı deneyimini önemser ve kaliteli içerik sunan web sitelerini tercih eder. Bu da web sitenizin kullanıcılar tarafından daha çok beğenilmesini ve geri dönüşlerin artmasını sağlar.\n4. Rekabet Avantajı: SEO, rekabet ortamında öne çıkmanızı sağlar. Rakiplerinizin önünde yer alarak daha fazla müşteriye ulaşabilir ve pazar payınızı artırabilirsiniz.",
      content4: "SEO'nun Avantajları:\n1. Uzun Vadeli Yatırım: SEO çalışmaları, uzun vadeli bir stratejidir. Sıralamalarda yükselmek zaman alabilir, ancak başarı elde edildiğinde sürekli bir organik trafik elde edersiniz.\n2. Ölçülebilirlik ve Analiz: SEO, web sitenizin performansını izlemenizi ve analiz etmenizi sağlar. Ziyaretçi sayısı, dönüşüm oranları, sıralamalar gibi verileri takip ederek stratejinizi optimize edebilirsiniz.\n3. Maliyet Etkinliği: SEO, diğer pazarlama yöntemlerine göre maliyet açısından daha etkili bir seçenektir. Organik arama sonuçlarından gelen trafik için reklam harcaması yapmanıza gerek kalmaz.\n4. Yerel SEO: Eğer yerel bir işletmeyseniz, yerel SEO stratejileriyle potansiyel müşterilerinizi hedef alabilirsiniz. Yerel aramalarda üst sıralarda yer almak, yerel müşterilere ulaşmanızı kolaylaştırır.\n\nSEO stratejileriyle işletmenizin çevrimiçi varlığını güçlendirmek ve rekabette öne çıkmak için uzman ekibimiz her zaman burada. Size özel SEO çözümleri sunarak daha fazla trafik, dönüşüm ve başarı elde etmenizi sağlayabiliriz. İşletmenizin büyümesine katkıda bulunmak için bize başvurabilirsiniz.",
      images: [seo1, seo2, ""]
    },
    {
      id: 5,
      Title: "İstatistik",
      content1: "Müşterilerinizin web sitesi hakkında gerçek zamanlı istatistiklere sahip olması, çevrimiçi varlığınızı anlamak ve geliştirmek için büyük bir avantaj sağlar.",
      content2: "Web Sitesi İstatistikleri Nedir?\nWeb sitesi istatistikleri, web sitenizin performansını, ziyaretçi davranışlarını ve etkileşimlerini izlemek için kullanılan verilerdir. Bu istatistikler, ziyaretçi sayısı, sayfa görüntülemeleri, demografik bilgiler, trafiğin kaynağı ve dönüşüm oranları gibi bilgileri içerir.",
      content3: "Neden Web Sitesi İstatistikleri Önemlidir?\n1. Performans Değerlendirmesi: Web sitesi istatistikleri, web sitenizin performansını değerlendirmenize yardımcı olur. Ziyaretçi sayıları, sayfa görüntülemeleri ve ortalama oturum süreleri gibi verileri analiz ederek hangi alanlarda başarılı olduğunuzu ve hangi alanlarda gelişim sağlamanız gerektiğini belirleyebilirsiniz.\n2. Ziyaretçi Davranışı Analizi: Web sitesi istatistikleri, ziyaretçilerinizin nasıl etkileşimde bulunduğunu ve hangi sayfalarda daha fazla zaman geçirdiğini anlamanızı sağlar. Bu bilgiler, kullanıcı deneyimini iyileştirmek, popüler içerikleri belirlemek ve dönüşüm oranlarını artırmak için önemlidir.\n3. Trafik Kaynaklarının Belirlenmesi: Web sitesi istatistikleri, web sitenize gelen trafiğin kaynağını belirlemenize yardımcı olur. Organik arama, sosyal medya, reklamlar veya diğer kaynaklar aracılığıyla gelen ziyaretçileri analiz ederek hangi kanalların size en fazla trafik sağladığını ve bu kanallar üzerindeki çalışmalarınızı optimize etmenizi sağlar.\n4. Dönüşüm İzleme: Web sitesi istatistikleri, dönüşüm oranlarınızı izlemenize olanak tanır. Hangi sayfalarda ve hangi etkileşimlerde dönüşümlerin gerçekleştiğini belirleyerek pazarlama stratejilerinizi ve web sitesi içeriğinizi dönüşümleri artırmak için optimize edebilirsiniz.",
      content4: "Web Sitesi İstatistiklerinin Avantajları:\n1. Veriye Dayalı Kararlar: Web sitesi istatistikleri, veriye dayalı kararlar almanızı sağlar. İstatistikler üzerinde yapılan analizler ve takip edilen trendler, işletmenizin dijital stratejisini yönlendirmenize yardımcı olur.\n2. Müşteri İhtiyaçlarını Anlama: Web sitesi istatistikleri, ziyaretçilerinizin ihtiyaçlarını ve ilgi alanlarını anlamanıza yardımcı olur. Böylece, müşteri odaklı içerik ve deneyimler sunarak müşteri memnuniyetini artırabilirsiniz.\n3. Rekabet Analizi: Web sitesi istatistikleri, rakiplerinizin performansını ve stratejilerini anlamanızı sağlar. Rakip analizi yaparak rekabet avantajı elde edebilir ve pazarlama çabalarınızı iyileştirebilirsiniz.\n4. İlerleme Takibi: Web sitesi istatistikleri, ilerlemenizi takip etmenize yardımcı olur. Belirlediğiniz hedeflere ulaşmak için performansınızı düzenli olarak değerlendirebilir ve iyileştirme fırsatlarını belirleyebilirsiniz.",
      images: [stat1, stat2, ""]
    },
    {
      id: 6,
      Title: "Online Satış",
      content1: "Müşterilerinize online satış imkanı sunmak, işletmenizin büyümesi ve rekabet avantajı elde etmesi için önemli bir adımdır.",
      content2: "Online Satış Nedir?\nOnline satış, ürünlerinizi veya hizmetlerinizi internet üzerinden müşterilere sunma ve satma işlemidir. Müşteriler, web siteniz veya çevrimiçi platformlar aracılığıyla ürünleri görüntüleyebilir, seçebilir ve satın alabilirler.",
      content3: "Neden Online Satış Yapmalısınız?\n1. Geniş Müşteri Kitlesi: Online satış, coğrafi sınırlamalardan bağımsız olarak potansiyel müşterilere ulaşmanızı sağlar. İnternetin küresel erişimi sayesinde, ürünlerinizi dünya çapında müşterilere sunabilir ve işletmenizin büyüme potansiyelini artırabilirsiniz.\n2. 7/24 Satış İmkanı: Online satış, müşterilere günün her saati ürünlerinizi satın alma imkanı sunar. Müşteriler, kendi tercih ettikleri zaman diliminde alışveriş yapabilir ve sipariş verebilirler. Böylece, satış potansiyelinizi artırır ve gelirinizi artırmada esneklik sağlarsınız.\n3. Daha Az Maliyet: Geleneksel perakende mağazalarına kıyasla, online satış işletme maliyetlerini azaltır. Fiziksel mağaza kirası, depolama maliyetleri ve personel giderleri gibi masraflardan kaçınabilirsiniz. Ayrıca, çevrimiçi pazarlama ve reklam stratejileriyle daha hedefli ve ekonomik bir şekilde müşterilere ulaşabilirsiniz.\n4. Kullanıcı Verileri ve Analitiği: Online satış, müşteri davranışlarını ve satış verilerini izleme ve analiz etme imkanı sağlar. Müşterilerin alışveriş tercihleri, satın alma alışkanlıkları ve demografik bilgileri gibi verileri kullanarak, pazarlama stratejilerinizi geliştirebilir ve müşteri deneyimini iyileştirebilirsiniz.",
      content4: "Online Satışın Avantajları:\n1. Kolay ve Hızlı Alışveriş: Müşteriler, birkaç tıklama ile ürünleri seçebilir ve satın alabilirler. Kolay arama, filtreleme ve ödeme seçenekleriyle müşteri deneyimi hızlı ve kullanıcı dostudur.\n2. Geniş Ürün Yelpazesi: Online satış, fiziksel mağazaların sınırlamalarını aşarak geniş bir ürün yelpazesini müşterilere sunar. Stok sınırlamaları olmadan, çeşitli ürünleri sergileme ve satma imkanınız vardır.\n3. İndirim ve Promosyonlar: Online satış platformları, indirimler, kampanyalar ve promosyonlar gibi pazarlama avantajları sunar. Müşteriler, özel fırsatlardan yararlanarak değerli alışveriş deneyimi yaşarlar.\n4. Kolay İade ve Müşteri Hizmetleri: Online satış, iade ve değişim işlemlerini kolaylaştırır. Müşteriler, ürünlerle ilgili sorularını sormak ve destek almak için müşteri hizmetleri kanallarını kullanabilirler.",
      images: [onlineSatis, onlineSatis2, ""]
    },

  ];

const businessModels =
  [
    {
      id: 1,
      Title: "Kurumsal Marka",
      content1: "Siz değerli iş sahipleri için başarılı bir kurumsal marka oluşturmanın önemini biliyoruz. Markanızın yüksek bir itibara sahip olması, hedef kitlenizi etkilemesi ve sizi rakiplerinizden ayıran benzersiz bir konumda olmanız gerekiyor. Bu nedenle, size modern bir web sitesine sahip olmanın neden önemli olduğunu anlatmak istiyoruz.",
      content2: "Kurumsal markanızı güçlendirmek ve daha fazla müşteriye ulaşmak için bir web sitesine sahip olmanızın sağlayacağı contentlar nelerdir?",
      content3: "İlk İzlenim: İnsanlar genellikle bir işletme hakkında bilgi edinmek ve güvenilirliklerini değerlendirmek için web sitelerini ziyaret ederler. İyi tasarlanmış ve profesyonel bir web sitesi, hedef kitlenize markanızı en iyi şekilde temsil etme ve olumlu bir ilk izlenim bırakma fırsatı sunar.",
      content4: "Güven ve İtibar: Kurumsal bir web sitesi, işletmenizin güvenilirliğini ve itibarını artırır. Müşteriler, işletmenizin web sitesinde sunulan bilgiler, referanslar ve müşteri geri bildirimleri aracılığıyla markanız hakkında daha fazla bilgi edinirler. Bu sayede, güven duygusu oluşturarak müşterilerin markanıza olan güvenini artırırsınız.",
      content5: "Marka Kimliği ve Deneyimi: Bir web sitesi, markanızın kimliğini ve değerlerini etkili bir şekilde yansıtabilmeniz için ideal bir platformdur. Tasarım, renkler, logo ve görseller aracılığıyla markanızın benzersizliğini vurgulayabilir ve hedef kitlenizin markanızla güçlü bir bağ kurmasını sağlayabilirsiniz. Aynı zamanda, web sitesi üzerinden müşterilere markanızla etkileşim kurma ve değerli bir deneyim yaşatma imkanı da sunarsınız.",
      content6: "Hedef Kitlenize Erişim: Web sitesi, işletmenizin hedef kitlesine ulaşma ve pazarlama faaliyetlerinizi destekleme konusunda önemli bir araçtır. SEO (Arama Motoru Optimizasyonu) çalışmalarıyla web sitenizi arama motorlarında üst sıralara çıkararak daha fazla potansiyel müşteriye erişebilirsiniz. Ayrıca, sosyal medya entegrasyonu, blog yazıları ve e-posta pazarlama gibi yöntemlerle web sitenizi kullanarak hedef kitlenize düzenli olarak değerli içerikler sunabilirsiniz.",
      content7: "Ürün ve Hizmet Sunumu: Web siteniz, ürün ve hizmetlerinizi potansiyel müşterilere açık ve anlaşılır bir şekilde sunmanızı sağlar. Detaylı ürün açıklamaları, görseller, videolar ve fiyatlandırma bilgileri aracılığıyla müşterilerin ihtiyaçlarına uygun ürünleri seçmelerine olanak tanırsınız. Bu da satışlarınızı artırmanızı sağlar.",
      content8: "Kısacası, kurumsal bir marka için bir web sitesine ihtiyacınız vardır çünkü web sitesi, markanızı tanıtma, güven oluşturma, hedef kitlenize erişim sağlama ve ürünlerinizi etkili bir şekilde sunma konularında size büyük avantajlar sunar. Profesyonel bir web sitesi oluşturmak ve kurumsal markanızı güçlendirmek için uzman ekibimiz size yardımcı olmaktan mutluluk duyar. Bize başvurarak markanızı çevrimiçi dünyada ön plana çıkarabilirsiniz.",
      images: ["", "", ""]
    },
    {
      id: 2,
      Title: "Kişisel Marka",
      content1: "Siz değerli kişisel marka sahipleri için, başarılı bir şekilde öne çıkmak ve hedef kitlenizi etkilemek önemlidir. Kişisel markanızı oluştururken, neden bir web sitesine ihtiyaç duyacağınızı ve size sağlayacağı contentları anlatmak istiyoruz.",
      content2: "1. Profesyonellik ve İtibar: Kişisel bir marka olarak, dijital bir varlığa sahip olmak, profesyonel bir izlenim bırakmanızı sağlar. Kendi web siteniz aracılığıyla, yeteneklerinizi, deneyimlerinizi ve başarılarınızı etkili bir şekilde sergileyebilirsiniz. Bu, hedef kitleniz üzerinde güven ve itibar oluşturmanıza yardımcı olur.",
      content3: "2. Kendi Kontrolünüz: Kişisel bir web sitesine sahip olmak, kendinizi tamamen ifade etme özgürlüğü sağlar. Kendi içeriklerinizi, projelerinizi, başarılarınızı ve hedeflerinizi istediğiniz şekilde paylaşabilirsiniz. Ayrıca, sosyal medya platformlarından bağımsız olarak, kendi marka imajınızı yönetebilir ve kontrol edebilirsiniz.",
      content4: "3. İş Fırsatları ve Ağ Oluşturma: Bir web sitesi, iş fırsatlarına erişim sağlar ve profesyonel ağınızı genişletmenize yardımcı olur. Potansiyel işverenler, müşteriler ve iş ortakları, web siteniz aracılığıyla sizinle iletişim kurabilir ve hakkınızda daha fazla bilgi edinebilirler. Ayrıca, blog yazıları, makaleler veya podcastler gibi içerikler üreterek sektördeki otorite olma konusunda da fırsatlar yaratırsınız.",
      images: ["", "", ""]
    },
    {
      id: 3,
      Title: "E-Ticaret",
      content1: "Siz değerli işletme sahipleri için, başarılı bir e-ticaret işletmesi kurmanın ve online satışları artırmanın önemini biliyoruz. İşletmenizin büyümesi ve rekabet avantajı elde etmeniz için neden kişisel bir e-ticaret sitesine ihtiyaç duyacağınızı anlatmak istiyoruz. İşte, işletmeniz için bir e-ticaret sitesine sahip olmanızın sağlayacağı contentları vurgulayan bir tanıtım metni:",
      content2: "1. Geniş Müşteri Tabanına Ulaşım: Kişisel bir e-ticaret sitesi, ürünlerinizi ve hizmetlerinizi dünya çapında geniş bir müşteri tabanına sunmanızı sağlar. Coğrafi sınırlamalar olmadan, müşterileriniz herhangi bir yerden sizinle alışveriş yapabilir. Bu, işletmenizin potansiyel müşterileri artırır ve satışlarınızı genişletir.",
      content3: "2. 7/24 Satış İmkanı: Bir e-ticaret sitesi, işletmenizin 7 gün 24 saat açık olmasını sağlar. Bu da müşterilerinizin istedikleri zaman ürünlerinizi inceleyebilmesi ve satın alabilmesi demektir. Fiziksel mağaza sınırlamaları olmadığı için, müşterilerinizin herhangi bir saatte alışveriş yapabilmesini sağlarsınız.",
      content4: "3. Kişiselleştirilmiş Alışveriş Deneyimi: Kişisel e-ticaret sitenizde, müşterilere kişiselleştirilmiş bir alışveriş deneyimi sunabilirsiniz. Müşterilerin tercihlerine ve geçmiş alışverişlerine dayanarak öneriler sunabilir, indirimler ve promosyonlar sunabilirsiniz. Böylece müşteri memnuniyetini artırır ve tekrarlayan müşterileri çekersiniz.",
      content5: "4. Ödeme Kolaylığı ve Güvenliği: E-ticaret sitenizde çeşitli ödeme seçenekleri sunarak müşterilere kolaylık sağlayabilirsiniz. Kredi kartı, banka transferi, dijital cüzdanlar gibi farklı ödeme yöntemlerini entegre edebilirsiniz. Ayrıca, güvenli ödeme sistemleri kullanarak müşterilerinizin ödemelerini güvence altına alabilirsiniz.",
      content6: "5. İşletme Verimliliği ve Otomasyon: Bir e-ticaret sitesi, işletme süreçlerinizi otomatikleştirmenizi sağlar. Stok takibi, sipariş yönetimi, faturalandırma gibi işlemleri otomatik hale getirerek zamandan ve kaynaklardan tasarruf sağlamanızı sağlar. Böylece daha verimli bir şekilde çalışabilir ve işletmenizi büyütebilirsiniz.",
      content7: "6. Pazarlama ve Büyüme Fırsatları: Kişisel e-ticaret siteniz, pazarlama ve büyüme için birçok fırsat sunar. SEO optimizasyonu, sosyal medya entegrasyonu, e-posta pazarlama gibi dijital pazarlama stratejilerini kullanabilirsiniz. Ayrıca, verileri analiz ederek müşteri davranışlarını anlayabilir ve işletmenizi büyütmek için stratejik kararlar alabilirsiniz.",
      content8: "İşletmenizin başarısını artırmak ve rekabette öne geçmek için bir kişisel e-ticaret sitesine sahip olmanız şiddetle öneriyoruz. Uzman ekibimiz, size en uygun çözümü sunmak için burada. İşletmenizin büyümesine katkıda bulunmak ve online satışlarınızı artırmak için bize başvurabilirsiniz.",
      images: ["", "", ""]
    },
    {
      id: 4,
      Title: "Pazarlama",
      content1: "Pazarlama stratejilerinizi web üzerinde yayımlayarak, kampanyanızı daha geniş kitlelere ulaştırmanız ve daha az çaba harcayarak etkili bir şekilde duyurmanız mümkün. Web, günümüzde insanların bilgiye erişim sağladığı, alışveriş yaptığı ve iletişim kurduğu önemli bir platform haline gelmiştir.",
      content2: "İşte web üzerinde pazarlama stratejileri kullanmanın bazı contentları:",
      content3: "Geniş Kitlelere Ulaşım: Web, dünya çapında milyarlarca insanın erişebildiği bir platformdur. Pazarlama stratejilerinizi web üzerinde yayımlayarak, coğrafi sınırlamalardan bağımsız olarak potansiyel müşterilerinize ulaşabilirsiniz. Web sayesinde hedef kitlenizin daha geniş bir kesimine ulaşarak marka bilinirliğinizi artırabilirsiniz.",
      content4: "Daha Az Maliyet: Web tabanlı pazarlama stratejileri, geleneksel pazarlama yöntemlerine göre genellikle daha düşük maliyetlidir. Web siteniz aracılığıyla içerik yayımlayabilir, sosyal medya platformlarında organik veya hedeflenmiş reklamlar verebilir ve e-posta pazarlaması gibi dijital araçları kullanarak etkili kampanyalar düzenleyebilirsiniz. Bu şekilde, pazarlama bütçenizi daha verimli kullanabilir ve geri dönüşüm oranınızı artırabilirsiniz.",
      content5: "Ölçülebilirlik ve Analiz: Web tabanlı pazarlama stratejileri, gerçek zamanlı veri analizi ve ölçülebilirlik imkanı sağlar. Web analitik araçları sayesinde, kampanyalarınızın performansını takip edebilir, ziyaretçi trafiğini, dönüşüm oranlarını ve diğer önemli metrikleri izleyebilirsiniz. Bu verilere dayanarak stratejilerinizi optimize edebilir ve daha iyi sonuçlar elde edebilirsiniz.",
      content6: "Hedeflenmiş Pazarlama: Web, hedeflenmiş pazarlama için etkili bir platform sunar. Sosyal medya ve diğer reklam kanalları aracılığıyla demografik özelliklere, ilgi alanlarına ve davranışlara göre hedeflenmiş reklamlar verebilirsiniz. Böylece, doğru kitleye doğru mesajları ileterek dönüşüm oranınızı artırabilirsiniz.",
      content7: "Etkileşim ve İletişim: Web tabanlı pazarlama stratejileri, müşterilerle etkileşim kurmanızı ve iletişim kurmanızı sağlar. Sosyal medya, bloglar, forumlar ve e-posta pazarlaması gibi araçlarla müşterilerinizle etkileşimde bulunabilir, geri bildirimleri toplayabilir ve müşteri sadakati oluşturabilirsiniz. Müşterilerinizin ihtiyaçlarına daha iyi cevap verebilir ve markanızı daha güçlü bir şekilde konumlandırabilirsiniz.",
      content8: "Web tabanlı pazarlama stratejileri, işletmenizin büyümesine katkıda bulunabilir ve rekabet avantajı elde etmenizi sağlayabilir. Uzman ekibimiz, size web tabanlı pazarlama stratejileri konusunda destek sağlamak için burada. İşletmenizin hedeflerine uygun çözümler sunmak ve başarılı bir dijital pazarlama stratejisi oluşturmak için bize başvurabilirsiniz.",
      images: ["", "", ""]
    },
    {
      id: 5,
      Title: "Online Katalog",
      content1: "Online kataloglar, işletmelerin ürünlerini ve hizmetlerini sanal bir ortamda sergileyerek müşterilere sunmalarını sağlayan etkili bir pazarlama aracıdır.",
      content2: "Geniş Kitlelere Erişim: Online kataloglar, internet üzerinden dünya genelinde milyonlarca potansiyel müşteriye erişmenizi sağlar.",
      content3: "Daha Fazla Görsel İmkan: Online kataloglar, ürün ve hizmetlerinizi görsel olarak etkileyici bir şekilde sunmanızı sağlar.",
      content4: "Kolay Güncelleme ve Yönetim: Online kataloglar, ürün veya hizmetlerinizdeki değişiklikleri hızlı ve kolay bir şekilde yapmanızı sağlar.",
      content5: "Maliyet Tasarrufu: Online kataloglar, geleneksel basılı kataloglara göre daha ekonomik bir seçenektir.",
      content6: "Kullanıcı Dostu Arayüz: Online kataloglar, kullanıcı dostu arayüzlerle tasarlanabilir.",
      content7: "Analiz ve İzleme: Online kataloglar, müşteri davranışlarını izleme ve analiz etme imkanı sunar.",
      content8: "Çevre Dostu Seçenek: Online kataloglar, basılı katalogların kağıt ve enerji tüketimini ortadan kaldırarak çevre dostu bir seçenektir.",
      images: ["", "", ""]
    },
    {
      id: 6,
      Title: "Rezervasyon Sistemi",
      content1: "24/7 hizmet: Müşteriler günün her saatinde rezervasyon yapabilir.",
      content2: "Kolay ve hızlı rezervasyon: Web sitesi veya mobil uygulama üzerinden birkaç adımda rezervasyon tamamlanabilir.",
      content3: "Otomatik doğrulama ve hatırlatmalar: Rezervasyonlar otomatik olarak doğrulanır ve müşterilere hatırlatmalar gönderilir.",
      content4: "Müşteri verilerinin toplanması: Müşteri bilgileri ve tercihleri analiz edilebilir.",
      content5: "Kapasite yönetimi ve planlama: İşletme, rezervasyonlar üzerinden yoğunlukları ve kaynak ihtiyaçlarını yönetebilir.",
      content6: "İşletme verimliliği ve gelir artışı: İş süreçleri otomatikleşir, personel verimliliği artar ve gelir potansiyeli yükselir",
      images: ["", "", ""]
    },
    {
      id: 7,
      Title: "Blog",
      content1: "Blog sahibi olmak, birçok insan için çeşitli nedenlerle ilgi çekici bir seçenektir.",
      content2: "İlgi ve Tutkularını Paylaşmak: Blog sahibi olmak, insanların ilgi ve tutkularını geniş bir kitleyle paylaşma fırsatı sunar.",
      content3: "Yaratıcılığı ve İfade Özgürlüğünü Kullanmak: Blog yazmak, yaratıcılığınızı serbest bırakmanızı sağlar.",
      content4: "Topluluk Oluşturmak ve İletişim Kurmak: Blog sahibi olmak, insanlarla bağlantı kurmanızı ve bir topluluk oluşturmanızı sağlar.",
      content5: "Kişisel Marka Oluşturmak: Bir blog, kişisel markanızı oluşturmanın etkili bir yoludur.",
      content6: "Gelir Elde Etme Potansiyeli: Blog sahibi olmak, çeşitli gelir elde etme fırsatları sunar.",
      content7: "Öğrenme ve Bilgi Paylaşma: Blog sahibi olmak, sürekli öğrenme ve bilgi paylaşma fırsatı sunar.",
      content8: "İnternet Dünyasında Varlık Oluşturmak: Blog sahibi olmak, dijital dünyada varlık oluşturmanın önemli bir yolu olarak kabul edilir.",
      images: ["", "", ""]
    },


  ]

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
    backgroundColor: naturalBlack
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
    backgroundColor: naturalBlack

  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2, backgroundColor: naturalBlack, color: companyRed }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CustomizedDialogs(props) {
  const navigate = useNavigate();
  const { id, open, setOpen, dialogType } = props;

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  let dialogContent;
  if (dialogType === 1) {
    dialogContent = dialogContents.find((dialogContent) => dialogContent.id == id)
  }
  else if (dialogType === 2) {
    dialogContent = businessModels.find((dialogContent) => dialogContent.id == id)
  }
  return (
    <div>
      <BootstrapDialog
        maxWidth='lg'
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          {dialogContent.Title}
        </BootstrapDialogTitle>
        <DialogContent dividers style={{ color: naturalWhite }}>
          <div className='dialog'>
            <div className='imageCon'>
              {dialogContent.images.map(image => image !== "" ? (<img src={image} alt='dynamicWeb' />) : <div></div>)}
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', marginTop: '20px' }}>
              <Typography className='center' gutterBottom align='left'>
                {dialogContent.content1 ? dialogContent.content1 : ''}
              </Typography>
              <Typography className='center' gutterBottom align='left'>
                {dialogContent.content2 ? dialogContent.content2 : ''}
              </Typography>
              <Typography className='center' gutterBottom align='left'>
                {dialogContent.content3 ? dialogContent.content3 : ''}
              </Typography>
              <Typography className='center' gutterBottom align='left'>
                {dialogContent.content4 ? dialogContent.content4 : ''}
              </Typography>
              <Typography className='center' gutterBottom align='left'>
                {dialogContent.content5 ? dialogContent.content5 : ''}
              </Typography>
              <Typography className='center' gutterBottom align='left'>
                {dialogContent.content6 ? dialogContent.content6 : ''}
              </Typography>
              <Typography className='center' gutterBottom align='left'>
                {dialogContent.content7 ? dialogContent.content7 : ''}
              </Typography>
              <Typography className='center' gutterBottom align='left'>
                {dialogContent.content8 ? dialogContent.content8 : ''}
              </Typography>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' style={{ color: 'white' }} size='large' autoFocus onClick={() => navigate('/contact',
            {
              state: {
                service: dialogContent.Title
              }
            })}>
            Bilgi al
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
