import React, { useState, useEffect, useRef } from "react";
import Grid from "@mui/material/Grid";
import { Grow, Link } from "@mui/material";

const GridDisplay = ({ gridItems }) => {
  return (
    <Grid
      container
      columnGap={{ xs: 0, md: 4 }}
      sx={{ justifyContent: "center", alignItems: "center" }}
    >
      {gridItems.map((item, index) => (
        <Grid item xs={6} md={2} key={index}>
          <Link sx={{textDecorationLine:'none'}} href={item.link}>
          <div >
            <img
              src={item.src}
              alt={item.alt}
              className={`image${index + 1}`}
              style={{ padding: 10, width: "100%", height: "auto" }}
            />
            <h3
              style={{ textAlign: "center", color: "whitesmoke" }}
              className="text"
            >
              {item.text}
            </h3>{" "}
          </div></Link>
        </Grid>
      ))}
    </Grid>
  );
};

export default GridDisplay;
