import React, { useState, useEffect, useRef } from 'react';
import './ScrollableBox.css'; // Import CSS file for styling

const ScrollableBox = ({ children }) => {
  const scrollableRef = useRef(null);
  const [featuredIndex, setFeaturedIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setFeaturedIndex(prevIndex => (prevIndex + 1) % children.length);
    }, 1000); // Change the duration to control the speed of auto-scrolling

    return () => clearInterval(interval);
  }, [children]);

  useEffect(() => {
    if (scrollableRef.current) {
      const containerWidth = scrollableRef.current.offsetWidth;
      const contentWidth = scrollableRef.current.scrollWidth;
      const maxScrollLeft = contentWidth - containerWidth;
      
      let scrollLeft;
      if (window.matchMedia("(min-width: 768px)").matches) {
        // If viewport width is at least 768px (desktop)
        scrollLeft = (featuredIndex *200 );
      } else {
        // If viewport width is less than 768px (not desktop)
        scrollLeft = (featuredIndex * ((containerWidth / 3) - 11)) - (containerWidth / 3-11);
      }
      // Clamp scrollLeft to keep it within the acceptable range
      scrollLeft = Math.max(0, Math.min(scrollLeft, maxScrollLeft));
  
      scrollableRef.current.scrollTo({
        left: scrollLeft,
        behavior: 'smooth'
      });
    }
  });

  return (
    <div className="scrollable-container" ref={scrollableRef}>
      <div className="scrollable-content">
        {children.map((child, index) => (
          <a key={index} href={child.props.link} className={`box ${index === featuredIndex ? 'featured' : ''}`}>
          {child}
        </a>
        ))}
      </div>
    </div>
  );
};

export default ScrollableBox;
