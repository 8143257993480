import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { NavLink as Link, Route, Routes, useNavigate } from 'react-router-dom'
import Home from '../pages/Home/Home';
import Contact from '../pages/Contact/Contact';
import { naturalWhite } from '../global/colors';
import softcavewareIcon from '../Resources/softcaveware.png'


const drawerWidth = 240;
const navItems = [
    { name: 'Hakkında', href: '/about' },
    { name: 'İletişim', href: '/contact' }
];

function DrawerAppBar(props) {
    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const navigate = useNavigate();
    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center', backgroundColor: '#1F1F29' }}>
            <Typography color='white' variant="h6" sx={{ my: 2 }}>
                <Link style={{ color: naturalWhite, fontFamily: 'sans-serif', fontSmooth: 'always', textDecorationLine: 'none' }} to={'/'}><img width={100} height={100} src={softcavewareIcon}></img></Link>
            </Typography>
            <Typography color='#61e4dc' variant="subtitle2" sx={{ my: 1 }}>
                Software Solutions
            </Typography>
            <Divider />
            <List>
                {navItems.map((item) => (
                    <ListItem key={item.name} disablePadding>
                        <ListItemButton sx={{ textAlign: 'center' }}>
                            <Link style={{ color: naturalWhite }} to={item.href}>{item.name}</Link>
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Box>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <Box sx={{ display: 'flex', backgroundColor: '#1F1F29' }}>
            <CssBaseline />
            <AppBar component="nav" style={{ backgroundColor: '#1F1F29' }}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ height: 64, mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon style={{ color: 'white' }} />
                    </IconButton>
                    <Typography
                        variant="h6"
                        component="div"
                        sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
                    >
                        <Link style={{ color: naturalWhite, fontFamily: 'sans-serif', fontSmooth: 'always', textDecorationLine: 'none' }} to={'/'}><img src={softcavewareIcon}></img></Link>
                    </Typography>
                    <Typography color='#61e4dc' variant="caption" sx={{ flexGrow: 100, display: { xs: 'none', sm: 'block' } }}
                    >
                        Software Solutions
                    </Typography>
                    <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                        {navItems.map((item) => {
                            let link = item.href;
                            return (
                                <Button key={item.name} sx={{ color: '#fff' }} onClick={() => navigate(link)}>
                                    {item.name}
                                </Button>)
                        }
                        )}
                    </Box>
                </Toolbar>
            </AppBar>
            <Box component="nav" sx={{ backgroundColor: '#1F1F29' }}>
                <Drawer
                    PaperProps={{
                        sx: {
                            backgroundColor: "#141526",
                            color: "red"
                        }
                    }}
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
            </Box>
        </Box>
    );
}

DrawerAppBar.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

export default DrawerAppBar;