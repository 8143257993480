import {
  Container,
  Divider,
  Grid,
  SpeedDial,
  SpeedDialAction,
  Typography,
} from "@mui/material";
import { naturalWhite } from "../../global/colors";
import {
  AdsClickRounded,
  ArrowDropDownCircleRounded,
  DataObjectRounded,
  Map,
} from "@mui/icons-material";
import koyden from "../../Resources/koy-den.png";
import travel from "../../Resources/lily_agency.png";
import blank from "../../Resources/blank.png";
import "./style.css";
import React, { useEffect, useRef } from "react";
import skyscrapper from "../../Resources/skyscraper.png";
import branding from "../../Resources/branding.png";
import ecommerce from "../../Resources/ecommerce.png";
import digitalmarketing from "../../Resources/digital-marketing.png";
import blog from "../../Resources/blog.png";
import booking from "../../Resources/booking.png";
import catalog from "../../Resources/catalog.png";
import CustomizedDialogs from "../../Components/dialog/CustomizedDialog";
import WorkRoundedIcon from "@mui/icons-material/WorkRounded";
import AppsRoundedIcon from "@mui/icons-material/AppsRounded";
import mainLogo from "../../Resources/softcaveware-low.png";
import { Slideshow } from "../../Components/dialog/ReferallSlider/referalls";
import ScrollableBox from "../../Components/Carousel/ScrollableBox";
import AutoscrollWaterFlow from "../../Components/AutoscrollWaterFlow/AutoscrollWaterFlow";

import scwshop from "../../Resources/scw-e-shop.png";
import scwbooking from "../../Resources/scw-online-booking.png";
import multipress from "../../Resources/multipress.webm";
import onlinebooking from "../../Resources/onlinebooking.webm";
import newsmodule from "../../Resources/scw-news-module.png";
import SendAnalytics from "../../analytics";
import commercemodulevideo from "../../Resources/demo.webm";
import newsmodulevideo from "../../Resources/newsmodulevideo.webm";
import tranierHubVideo from "../../Resources/tranierHubVideo.webm";
import GridDisplay from "../../Components/grid-display";
import ceo from "../../Resources/references/ceohaber.png";
import cmo from "../../Resources/references/cmo.png";
import mentor from "../../Resources/references/MentorHaberLogo.png";
import olayyeri from "../../Resources/references/OlayYeriLogo.png";
import patronhaber from "../../Resources/references/patronhaber.png";
import protokol from "../../Resources/references/ProtokolHaberLogo.png";
import tapuhaber from "../../Resources/references/Tapu-Haber-Logo.png";
import websiad from "../../Resources/references/Wbsiad-Logo.png";
import koydenmarket from "../../Resources/references/koydenmarket.webp";
const actions = [
  { icon: <DataObjectRounded />, name: "Hizmetler" },
  { icon: <WorkRoundedIcon />, name: "İş Modelleri" },
  { icon: <AppsRoundedIcon />, name: "Projeler" },
];

const products = [
  {
    description: "e-ticaret, online mağaza",
    link: "https://commercemodule.softcaveware.com",
    video: commercemodulevideo,
  },
  {
    description: "online rezervasyon sistemi",
    link: "https://softcaveware-online-booking.netlify.app/",
    video: tranierHubVideo,
  },
  {
    description:
      "News websites manage and publish system , haber sitesi kurulum, yönetim ve paylaşım sistemi",
    link: "https://newsmodulebysoftcaveware.netlify.app/",
    video: newsmodulevideo,
  },

  {
    description: "Wordpress multi sharing system",
    link: "https://multipress.tech/",
    video: multipress,
  },
  {
    description: "",
    link: "",
  },
  {
    description: "Booking Module",
    link: "https://bookingmodule.softcaveware.com/",
    video: onlinebooking,
  },
];

const dataportfolio = [
  {
    description: "e-ticaret, online mağaza",
    link: "https://koydenyoreselgidamarket.com",
    img: scwshop,
  },
  {
    description: "online rezervasyon sistemi",
    link: "https://softcaveware-online-booking.netlify.app/",
    img: scwbooking,
  },
  {
    description: "Wordpress multi sharing system",
    link: "https://multipress.tech/",
    img: multipress,
  },
  {
    description:
      "News websites manage and publish system , haber sitesi kurulum, yönetim ve paylaşım sistemi",
    link: "https://newsmodulebysoftcaveware.netlify.app/",
    img: newsmodule,
  },
];
const references = [
  { src: ceo, alt: "ceohaber", text: "CEO Haber",link:"https://ceohaber.com" },
  { src: cmo, alt: "cmo", text: "CMO",link:"https://cmohaber.com" },
  { src: mentor, alt: "MentorHaberLogo", text: "Mentor Haber",link:"https://ceohaber.com" },
  { src: olayyeri, alt: "OlayYeriLogo", text: "Olay Yeri",link:"https://mentorhaber.com" },
  { src: patronhaber, alt: "patronhaber", text: "Patron Haber",link:"https://patronhaber.com" },
  { src: protokol, alt: "ProtokolHaberLogo", text: "Protokol Haber",link:"https://protokolhaber.com" },
  { src: tapuhaber, alt: "Tapu-Haber-Logo", text: "Tapu Haber",link:"https://tapuhaber.com" },
  { src: websiad, alt: "Wbsiad-Logo", text: "Websiad",link:"https://websiad.com" },
];
export default function Home() {
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const [open, setOpen] = React.useState(false);
  const [dialogId, setDialogId] = React.useState(0);
  const [dialogType, setDialogType] = React.useState(0);
  const handleClickOpen = (id, type) => {
    setDialogId(id);
    setDialogType(type);
    setOpen(true);
  };
  const videoRefs = useRef([]);
  useEffect(() => {
    videoRefs.current.forEach((videoRef, index) => {
      const playVideoWithDelay = () => {
        if (videoRef) {
          setTimeout(() => {
            videoRef.play().catch((error) => {
              console.error("Error playing video:", error);
            });
          }, index * 1000); // Delay by 2000ms (2 seconds) for each video
        }
      };
      playVideoWithDelay();
    });
  }, []);

  useEffect(() => {
    SendAnalytics("Home");
  }, []);

  function handleScroll(name) {
    switch (name) {
      case "Hizmetler":
        document.getElementById("services")?.scrollIntoView();
        break;
      case "Projeler":
        document.getElementById("projects")?.scrollIntoView();
        break;
      case "İş Modelleri":
        document.getElementById("business")?.scrollIntoView();
        break;
    }
  }
  const boxes = references.map((item, i) => (
    <div key={i} link={item.link} className="box">
      <img src={item.img} />
    </div>
  ));
  return (
    <React.Fragment>
      <div class="img">
        <p className="blured">
          <img src={mainLogo} width={400} height={400}></img>
        </p>
      </div>
      <Typography
        id="projects"
        className="subtitle"
        marginY="10px"
        variant="h4"
        fontFamily="sans-serif"
        style={{ textAlign: "center" }}
      >
        Ürünlerimiz
      </Typography>
      <Grid container marginY="0px">
        {products.map((data, i) => {
          return (
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              xl={4}
              style={{ padding: "5px" }}
              key={i}
            >
{data.video &&               <div className="po_item">
                <video
                  ref={(el) => (videoRefs.current[i] = el)} // Store ref dynamically
                  playsInline
                  muted
                  style={{ width: "100%", borderRadius: 10 }}
                >
                  <source src={data.video} type="video/webm" />
                  Your browser does not support the video tag.
                </video>
                <div className="content">
                  <a
                    style={{ backgroundColor: "whitesmoke", color: "black" }}
                    href={data.link}
                  >
                    Projeyi gör.
                    <AdsClickRounded fontSize="large" />
                  </a>
                </div>
              </div>}
            </Grid>
          );
        })}
      </Grid>
      
      <Divider />
      <Typography
        id="services"
        className="subtitle"
        marginTop="50px"
        variant="h4"
        fontFamily="sans-serif"
        style={{ textAlign: "center" }}
      >
        Hizmetler
      </Typography>
      <div></div>
      {/* <AutoscrollWaterFlow>
                {boxes}

      </AutoscrollWaterFlow> */}
      <ul class="tilesWrap">
        <li>
          <h2>01</h2>
          <h3>Web Tasarım</h3>
          <p>Ekran çözünürlüklerine uyumlu responsive web tasarımları.</p>
          <button onClick={() => handleClickOpen(1, 1)}>Devamını gör..</button>
        </li>
        <li>
          <h2>02</h2>
          <h3>Static&Dinamik Web</h3>
          <p>
            Tercihe ve ihtiyaca yönelik statik veya dinamik web uygulamaları.
          </p>
          <button onClick={() => handleClickOpen(2, 1)}>Devamını gör..</button>
        </li>
        <li>
          <h2>03</h2>
          <h3>Back-end/Server Geliştirme</h3>
          <p>
            E-Ticaret ve rezervasyon vb. web/mobil uygulamaları için
            Back-end/Server desteği.
          </p>
          <button onClick={() => handleClickOpen(3, 1)}>Devamını gör..</button>
        </li>
        <li>
          <h2>04</h2>
          <h3>SEO</h3>
          <p>
            Website bilinirliği ve kullanımını artırmak alan adını
            popülerleştirmek amacıyla arama motoru optimizasyon hizmeti.
          </p>
          <button onClick={() => handleClickOpen(4, 1)}>Devamını gör..</button>
        </li>
        <li>
          <h2>05</h2>
          <h3>İstatistik</h3>
          <p>
            Web/Mobil uygulamaların tıklanma, ziyaret, interaktifliğinin ülke,
            şehir, cihaz bazlı raporlarının düzenli olarak çıkarılması.
          </p>
          <button onClick={() => handleClickOpen(5, 1)}>Devamını gör..</button>
        </li>
        <li>
          <h2>06</h2>
          <h3>Online Satış</h3>
          <p>Web üzerinden online satış yapabilme olanakları..</p>
          <button onClick={() => handleClickOpen(6, 1)}>Devamını gör..</button>
        </li>
      </ul>
      <Typography
        id="services"
        className="subtitle"
        variant="h4"
        fontFamily="sans-serif"
        style={{ textAlign: "center", marginBottom: "50px" }}
      >
        Müşterilerimiz
      </Typography>
      <Typography
        id="services"
        className="subtitle"
        variant="h5"
        fontFamily="sans-serif"
        style={{ textAlign: "center", marginBottom: "50px" }}
      >
        E-Ticaret
      </Typography>
      <section className="jobs">
        <div className="jobs-content">
          <GridDisplay
            gridItems={[
              {
                src: koydenmarket,
                alt: "koyden market logo",
                text: "Köyden Yöresel Market",
                link:"https://koydenmarket.com"
              },
            ]}
          />
          {/* <div className="grid-container-categories"></div> */}
        </div>
      </section>
      <Typography
        id="services"
        className="subtitle"
        marginTop="50px"
        variant="h5"
        fontFamily="sans-serif"
        style={{ textAlign: "center", marginBottom: "50px" }}
      >
        İçerik Yönetimi
      </Typography>
      <section className="jobs">
        <div className="jobs-content">
          <GridDisplay gridItems={references} />
          {/* <div className="grid-container-categories"></div> */}
        </div>
      </section>
      <Container>
        <Typography
          id="business"
          className="subtitle"
          marginY="10px"
          variant="h4"
          fontFamily="sans-serif"
          style={{ textAlign: "center" }}
        >
          İş Modelleri
        </Typography>
        <div class="content">
          <ul class="team">
            <li class="member">
              <div class="thumb">
                <img src={skyscrapper} />
              </div>
              <div class="description">
                <h3>Kurumsal Marka</h3>
                <p>
                  Markanızı web üzerinden etkili bir şekilde tanıtıp markanızı
                  güçlendirin.
                  <a onClick={() => handleClickOpen(1, 2)}>Devamını gör..</a>
                </p>
              </div>
            </li>
            <li class="member">
              <div class="thumb">
                <img src={branding} />
              </div>
              <div class="description">
                <h3>Kişisel Marka</h3>
                <p>
                  Kişisel markalaşmanız için bir portfolio tasarımı hizmeti
                  alıp, kişisel yeteneklerinizi, tecrübelerinizi ve ilgi
                  alanlarınızı akılda kalıcı ve özgün bir şekilde sunun.
                  <a onClick={() => handleClickOpen(2, 2)}>Devamını gör..</a>
                </p>
              </div>
            </li>
            <li class="member co-funder">
              <div class="thumb">
                <img src={ecommerce} />
              </div>
              <div class="description">
                <h3>E-Ticaret</h3>
                <p>
                  İşletmeniz için müşterilerin telefonları veya bilgisayarlarını
                  kullanarak bir kaç tıklama ile işletmenizin alan adından
                  alışveriş yapabilmesine olanak sağlayarak müşteri bazında özel
                  kalmasını sağlayın.
                  <a onClick={() => handleClickOpen(3, 2)}>Devamını gör..</a>
                </p>
              </div>
            </li>
            <li class="member">
              <div class="thumb">
                <img src={digitalmarketing} />
              </div>
              <div class="description">
                <h3>Pazarlama</h3>
                <p>
                  Pazarlama stratejilerinizi Web'de yayımlarak kampanyanızı daha
                  geniş kitlelere daha az efor ile duyurun.
                  <a onClick={() => handleClickOpen(4, 2)}>Devamını gör..</a>
                </p>
              </div>
            </li>
            <li class="member">
              <div class="thumb">
                <img src={catalog} />
              </div>
              <div class="description">
                <h3>Online Katalog</h3>
                <p>
                  Müşterilerinizin hizmet veya ürünlerinize detaylı, medya ile
                  zenginleştirilmiş içerikler ile kolaylıkla erişimini sağlayın.
                  <a onClick={() => handleClickOpen(5, 2)}>Devamını gör..</a>
                </p>
              </div>
            </li>
            <li class="member co-funder">
              <div class="thumb">
                <img src={booking} />
              </div>
              <div class="description">
                <h3>Rezervasyon Sistemi</h3>
                <p>
                  Otel,araç kiralama, festival, etkinlik vb. hizmet veren
                  şirketiniz için potansiyel müşterilerinizin kolaylıkla
                  rezervasyon yapmasını sağlayın. Bu sayede sizde
                  rezervasyonlarınızı online yönetebileceksiniz. E-mail
                  destekli.
                  <a onClick={() => handleClickOpen(6, 2)}>Devamını gör..</a>
                </p>
              </div>
            </li>
            <li class="member">
              <div class="thumb">
                <img src={blog} />
              </div>
              <div class="description">
                <h3>Blog</h3>
                <p>
                  Şahsınıza özel tasarımı ve alan adı ile fotoğraf ve videoda
                  dahil olmak üzere içerik üretebileceğiniz bir blog sahibi
                  olun.
                  <a onClick={() => handleClickOpen(7, 2)}>Devamını gör..</a>
                </p>
              </div>
            </li>
          </ul>
        </div>
      </Container>
      <Container>

        <Divider />
      </Container>
      {open && (
        <CustomizedDialogs
          id={dialogId}
          open={open}
          setOpen={setOpen}
          dialogType={dialogType}
        />
      )}
      <SpeedDial
        ariaLabel="SpeedDial openIcon example"
        style={{ position: "fixed", bottom: "16px", right: "16px" }}
        icon={<Map openIcon={<ArrowDropDownCircleRounded />} />}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={() => handleScroll(action.name)}
          />
        ))}
      </SpeedDial>
    </React.Fragment>
  );
}
