import "./style.css";
import samilalgul from "../../Resources/samilalgul.jpg";
import {
  AdsClick,
  Facebook,
  FacebookRounded,
  Instagram,
  X,
} from "@mui/icons-material";

export default function About() {
  return (
    <div class="main">
      <div className="about-container">
        <section className="about-section">
          <h2>Vizyonumuz</h2>
          <p>
            Softcaveware olarak, teknolojiyi kullanarak iş dünyasını daha
            verimli ve sürdürülebilir hale getirmeyi hedefliyoruz. Yenilikçi
            çözümlerimizle, müşterilerimize ve topluma değer katmayı
            amaçlıyoruz.
          </p>
        </section>

        <section className="about-section">
          <h2>Misyonumuz</h2>
          <p>
            Misyonumuz, yüksek kaliteli B2B yazılım hizmetleri sunarak
            müşterilerimizin iş hedeflerine ulaşmalarını desteklemektir. Müşteri
            odaklı yaklaşımımız ve sürekli yenilikçi yaklaşımlarımız ile
            sektördeki en iyi çözümleri sağlamayı taahhüt ediyoruz.
          </p>
        </section>

        <section className="about-section">
          <h2>İnsanlar</h2>
          <p>
            Softcaveware'ın başarısının arkasında yetenekli ve tutkulu bir ekip
            bulunuyor. Her birey, şirketimizin değerlerini ve vizyonunu
            paylaşarak, mükemmeliyet odaklı projeler üzerinde çalışmaktadır.
          </p>
        </section>

        <section className="about-section">
          <h2>İletişim</h2>
          <p>
            Bizimle iletişime geçmek için lütfen{" "}
            <a href="mailto:softcaveware@gmail.com">softcaveware@gmail.com</a>{" "}
            adresini kullanın.
          </p>
        </section>
      </div>
      <div style={{margin:10,display:'flex',alignItems:'center',justifyContent:'center'}}>
        <div class="profile-card">
          <div class="img">
            <img src={samilalgul} />
          </div>
          <div class="caption">
            <h3>Şamil Algül</h3>
            <p>Founder</p>
            <div class="social-links">
              <a href="#">
                <FacebookRounded />
              </a>
              <a href="https://www.instagram.com/samilalgul_/">
                <Instagram />
              </a>
              <a href="https://x.com/softcaveware">
                <X />
              </a>
              <a href="https://samilalgul.com">
                <AdsClick />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
